import React from 'react';
import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import DateTimeInput from 'features/consults/components/tabFields/DateTimeInput';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { Box, FlexBox } from 'styles/layout';
import { getValueToPositive } from 'features/consults/shared/tabs/Scores/utils';
import ScoreGroup from 'features/consults/shared/tabs/Scores/ScoreGroup';
import SetZeroButton from 'features/consults/shared/tabs/Scores/SetZeroButton';
import ClearGroupButton from 'features/consults/shared/tabs/Scores/ClearGroupButton';

import schema from './validationSchema';
import fieldGroups from './data';
import fields from './formFields';

const ScoresNeuro = () => {
  const form = useFormState(schema, {
    stateSlice: 'consults',
    showAsSnackbar: true,
  });
  const {
    consult,
    consultTabData,
    isEditable,
    isMacro,
    handleFieldChange,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.SCORES, form.getValues);

  useConsultInitialFormState(consult.id, consultTabData, (values) => {
    form.setFormValues({
      ...values,
      [fieldGroups.VAN.name]: getValueToPositive(values[fieldGroups.VAN.name]),
    });
  });

  return (
    <Grid container>
      <Grid item sm={12} lg={7}>
        <Grid item xs={12}>
          <Box mb={3}>
            <DateTimeInput
              control={form.control}
              name={fields.nihssExamTimeAt.name}
              label={fields.nihssExamTimeAt.label}
              onChange={handleFieldChange}
              disabled={isMacro || !isEditable}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FlexBox mb={3}>
            <Box>
              <SetZeroButton
                form={form}
                disabled={!isEditable}
                label="Set NIHSS to 0"
                group={fieldGroups.NIHSS}
                onSave={saveConsultChange}
              />
            </Box>
            <Box ml={3}>
              <ClearGroupButton
                form={form}
                disabled={!isEditable}
                label="Clear NIHSS"
                group={fieldGroups.NIHSS}
                onSave={saveConsultChange}
              />
            </Box>
          </FlexBox>
        </Grid>
        <ScoreGroup
          form={form}
          group={fieldGroups.NIHSS}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
      </Grid>
      <Grid item sm={12} lg={5}>
        <ScoreGroup
          form={form}
          header="VAN Screening"
          group={fieldGroups.VAN}
          disabled={!isEditable}
          onSave={saveConsultChange}
          isPositive
        />
        <ScoreGroup
          form={form}
          group={fieldGroups.ICH}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
        <ScoreGroup
          form={form}
          group={fieldGroups.HUNT}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
        <ScoreGroup
          form={form}
          group={fieldGroups.ABCD2}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
      </Grid>
    </Grid>
  );
};

export default ScoresNeuro;
